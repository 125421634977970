export default function BandBio() {
  return (
    <div className="band-bio-container" id="bio">
      <div className="band-bio-wrapper">
        <div className="band-bio-content">
          <p>
            Sky DRFTR started in a dirty one bedroom apartment sometime in 2016.
            Longtime friends Laramie Lake and Ryan Cassell began throwing song
            ideas around on an electronic drum kit, a telecaster, and a pro
            tools rig. It existed as an idea and a scattered handful of song
            demos until winter 17/18, when a new practice space and the addition
            of another lifelong friend on bass, Nick Potter. After
            unsuccessfully looking for a vocalist, Laramie fell into the roll
            himself. The band was later completed by Landon Lake, brother of
            Laramie. Landon later had to move away from the band for work
            reasons and was replaced by Levi Holbert who has been a very
            welcomed addition to the group. Sky DRFTR began picking up speed,
            playing their first show and releasing their first two singles,
            “Trippy Dreamin” and “Crows and Carnivores” right as the pandemic
            shutdown was creeping into place. The band used the shutdown to go
            back into writing mode and began working in what would later become
            their first album, “Johnny Harpoon”. The album contains seven songs
            that stand up on their own but are stronger when enjoyed as a single
            body of work. With influences ranging from Coheed and Cambria,
            Thrice, Minus the bear, He is Legend, and countless other groups.
            Sky DRFTR delivers songs with powerful hooks and intricate riffs,
            complimented by strong instrumental sections and driving drums.
            Stream all of Sky DRFTRs catalogue where ever you listen to music.
          </p>
        </div>
      </div>
    </div>
  );
}
