export const flyerImages = [
  {
    id: 0,
    image: "/img/elko-show.jpg",
    alt: "Elko Show",
  },
  {
    id: 1,
    image: "/img/dead_animal_show.jpg",
    alt: "Dead Animal Show",
  },

  {
    id: 2,
    image: "/img/feb-show.jpeg",
    alt: "Feburary Show",
  },

  {
    id: 3,
    image: "/img/LBS-show.jpeg",
    alt: "LBS Show",
  },

  {
    id: 4,
    image: "/img/summer-daze-show.jpg",
    alt: "Sumer Daze Show",
  },
];
